define(['lodash', 'skins', 'controller/skins/skins.json', 'componentsCore'], function (_, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const controller = {
        displayName: 'Controller',

        mixins: [componentsCore.mixins.skinBasedComp],

        getSkinProperties() {
            return {
                '': {
                    style: {
                        width: '',
                        height: ''
                    }
                }
            };
        }
    };

    componentsCore.compRegistrar.register('platform.components.AppController', controller);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return controller;
});
